import { createClient } from "@supabase/supabase-js";

const options = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
};

export const supabase = createClient(
  process.env.DATABASE_URL || "https://pgyqllxxgfayfwpkehmq.supabase.co",
  process.env.DATABASE_KEY ||
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBneXFsbHh4Z2ZheWZ3cGtlaG1xIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODUxMTEwNTMsImV4cCI6MjAwMDY4NzA1M30.gmrpt6WicLEUfU5uhwiL2ee1ggGAzgLaIZ_vKcNXKqE",
  options
);

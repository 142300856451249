import "./AppDownloads.css";

import * as React from "react";

import { Link } from "react-router-dom";
import android from "../images/google-play-badge.png";
import ios from "../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg";

export interface IAppDownloadsProps {}
export function AppDownloads(props: IAppDownloadsProps) {
  return (
    <div className="AppDownloads-download-buttons">
      <Link
        to="https://apps.apple.com/us/app/statsdraft/id6451419726"
        target="_BLANK"
      >
        <img className="AppDownloads-iosImage" src={ios} alt="ios download" />
      </Link>
      <Link
        to="https://play.google.com/store/apps/details?id=com.statsdraft.app"
        target="_BLANK"
      >
        <img
          className="AppDownloads-androidImage"
          // onClick={download}
          src={android}
          alt="android download"
        />
      </Link>
    </div>
  );
}

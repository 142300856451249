import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import EventCard from "./EventCard";
import { getUpcomingEventsByLocationId } from "../../utils/LocationEventsDatabase";

function UpcomingEvents({ locationId, slug }) {
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const doThis = async (lid) => {
      console.log("Looking up events", lid);
      setEvents(await getUpcomingEventsByLocationId(lid));
      setLoading(false);
    };
    if (locationId) {
      doThis(locationId);
    }
  }, [locationId]);

  return (
    <>
      {loading && <CircularProgress />}
      <div className="eventCards">
        {events &&
          events.map((evnt) => (
            <EventCard key={evnt.id} evnt={evnt} slug={slug} />
          ))}
      </div>
    </>
  );
}

export default UpcomingEvents;

import "./AdminNavigator.css";

import { Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminNavigator = () => {
  const navigate = useNavigate();

  return (
    <div className="AdminNavigator-container">
      <div className="AdminNavigator-innerContainer">
        <h2>Admin Links</h2>

        <Link
          style={{ cursor: "pointer", paddingBottom: 4, paddingLeft: 4 }}
          onClick={() => {
            navigate("sport-segmentation-management");
          }}
        >
          Sport Segmentation Manager
        </Link>
        <Link
          style={{ cursor: "pointer", paddingBottom: 4, paddingLeft: 4 }}
          onClick={() => {
            navigate("/admin/game-management");
          }}
        >
          Game Manager
        </Link>
        <Link
          style={{ cursor: "pointer", paddingBottom: 4, paddingLeft: 4 }}
          onClick={() => {
            navigate("/admin/stat-management");
          }}
        >
          Stat Manager
        </Link>
        <Link
          style={{ cursor: "pointer", paddingBottom: 4, paddingLeft: 4 }}
          onClick={() => {
            navigate("/admin/event-management");
          }}
        >
          Event Manager
        </Link>
        <Link
          style={{
            cursor: "pointer",
            paddingBottom: 4,
            paddingLeft: 4,
            marginTop: 10,
          }}
          onClick={() => {
            navigate("/admin/reports");
          }}
        >
          Reports
        </Link>
      </div>
    </div>
  );
};

export default AdminNavigator;

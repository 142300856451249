import { supabase } from "../supabase";

const TABLE_NAME = "food_drink_specials";
const MAPPING_TABLE_NAME = "event_to_specials_mapping";

export async function getDealsByEventId(eventId) {
  const { data, error } = await supabase
    .from(MAPPING_TABLE_NAME)
    .select(
      `${TABLE_NAME}(id,display_name,discount_text,img_url,coupon_code,description)`
    )
    .eq("location_event_id", eventId);
  if (error) {
    console.error(error);
  } else {
    return data;
  }
}

import "./JoinLeagueButton.css";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import React, { useContext, useState } from "react";

import { AuthContext } from "../providers/AuthProvider";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

const JoinLeagueButton = ({ league, joinFunction }) => {
  const { session } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const avatar = (league) => (
    <>
      {league.sport_icon === "basketball" && <SportsBasketballIcon />}
      {league.sport_icon === "robot" && <PrecisionManufacturingIcon />}
      {league.sport_icon === "soccer" && <SportsSoccerIcon />}
      {league.sport_icon === "football" && <SportsFootballIcon />}
    </>
  );

  return (
    <Card className="joinLeagueButtonContainer">
      <CardHeader
        className="joinLeagueButtonHeader"
        avatar={avatar(league)}
        title={league.displayName}
        action={
          <div style={{ width: "24px", height: "24px", marginLeft: "16px" }}>
            &nbsp;
          </div>
        }
      />
      <CardContent className="joinLeagueButtonContent">
        <div>Sport: {league?.sportName}</div>
        <div>Games: {league?.seasonName} </div>
        <div>
          Type: {league?.type === "FFA" ? "Free for All" : "Snake Draft"}
        </div>
        <div>Members: {league?.members.length}</div>
        <div>End Date: {league?.endDate}</div>
        {(league?.type === "FFA" && (
          <div>Join By: {new Date(league?.draftStart).toLocaleString()}</div>
        )) || (
          <div>Drafts At: {new Date(league?.draftStart).toLocaleString()}</div>
        )}
        <Box sx={{ width: "100%", marginTop: 1, height: 10 }}>
          {loading && <LinearProgress />}
        </Box>
      </CardContent>
      <CardActions className="joinLeagueCardActions">
        {(league?.members.includes(session.user.id) && (
          <div>
            <Button color="primary" variant="outlined" disabled={true}>
              Joined
            </Button>
            <Button
              style={{ marginLeft: 4 }}
              href={`statsdraft://team/${league.id}`}
            >
              Launch
            </Button>
          </div>
        )) || (
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              await joinFunction(league?.id);
              setLoading(false);
            }}
          >
            {loading ? "Joining" : "Join"}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default JoinLeagueButton;

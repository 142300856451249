import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Success() {
  const navigate = useNavigate();
  const download = () => {
    navigate("/download");
  };
  return (
    <div className="block-1">
      <div className="welcome">Success</div>
      <div className="formBox">
        <div className="form-message-text">You account is now validated.</div>
        <Button variant="contained" size="large" onClick={download}>
          Download the App
        </Button>
      </div>
    </div>
  );
}

export default Success;

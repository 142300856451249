import { AppDownloads } from "../components/AppDownloads";
import React from "react";

function Download() {
  return (
    <div className="block-1">
      <div className="welcome">Download from the App Store</div>
      <AppDownloads />
    </div>
  );
}

export default Download;

import React, { createContext, useEffect, useState } from "react";

import { supabase } from "../supabase";
import { usePostHog } from "posthog-js/react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [profile, setProfile] = useState();
  const posthog = usePostHog();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  async function updateProfile() {
    // console.log("Updating Profile", session);
    if (session && session.user && session.user.id) {
      const thisProfile = await fetchMyProfile(session.user.id);
      if (thisProfile) {
        setProfile(thisProfile);
        if (thisProfile) {
          // console.log(
          //   `Setting Identity in PostHog Analytics: ${thisProfile.id}, ${thisProfile.email}, ${thisProfile.display_name}`
          // );
          try {
            posthog.identify(thisProfile.id, {
              email: thisProfile.email,
              name: thisProfile.display_name,
            });
          } catch (err) {
            console.error("error setting up PostHog Analytics", err);
          }
        }
      }
    }
  }

  const fetchMyProfile = async (sessionProfileId) => {
    const { data, error } = await supabase
      .from("profiles_full_info")
      .select("*")
      .eq("id", sessionProfileId)
      .limit(1)
      .single();
    if (error) {
      throw new Error("Error Refreshing Profile");
    } else {
      return data;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        profile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

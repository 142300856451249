import "./DealCard.css";

import { Box } from "@mui/material";
import React from "react";

function DealCard({ deal }) {
  return (
    <Box className="dealCard" key={deal?.id}>
      <h2 className="dealCardDiscountText">
        {deal?.food_drink_specials?.discount_text}
      </h2>
      <div className="dealCardHeader">
        {deal?.food_drink_specials?.display_name}
      </div>
      <img
        className="dealCardImg"
        src={deal?.food_drink_specials.img_url}
        alt="Deal"
      />
      <div className="dealCardDesc">
        {deal?.food_drink_specials?.description}
      </div>
    </Box>
  );
}

export default DealCard;

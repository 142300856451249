import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import DealCard from "../../components/DealCard";
import { Helmet } from "react-helmet-async";
import { getDealsByEventId } from "../../utils/LocationEventDealsDatabase";
import { getLocationBySlug } from "../../utils/LocationsDatabase";
import { useParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

function EventDeals() {
  const { locationId, eventId } = useParams();
  const [location, setLocation] = useState();
  const [deals, setDeals] = useState();
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    const doLookup = async () => {
      if (locationId) {
        setLoading(true);
        const loc = await getLocationBySlug(locationId);
        if (loc) {
          setLocation(loc);
          try {
            posthog.capture(`In Person Ad Loaded,{'locationId':${locationId}}`);
          } catch (err) {
            console.warn(err);
          }
        } else {
          setLocation(undefined);
        }
      } else {
        setLocation(undefined);
      }
      setLoading(false);
    };
    doLookup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    const doLookup = async () => {
      if (eventId) {
        const theseDeals = await getDealsByEventId(eventId);
        setDeals(theseDeals);
      }
    };
    doLookup();
  }, [eventId]);

  return (
    <>
      <Helmet>
        <title>STATSdraft - Event Deals</title>
      </Helmet>
      <div className="block-1">
        {(loading && (
          <div>
            <CircularProgress />
          </div>
        )) || (
          <>
            <img
              src={location?.logo}
              alt="Location Logo"
              className="eventLogo"
            />
            <h2>{location?.display_name}</h2>
            <h3>Event Deals</h3>
            {deals &&
              deals.map((deal) => <DealCard key={deal?.id} deal={deal} />)}
          </>
        )}
      </div>
    </>
  );
}

export default EventDeals;

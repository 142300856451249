import "./JoinLeague.css";

import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";

import { AppDownloads } from "../components/AppDownloads";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { useEffect } from "react";

function JoinLeague() {
  const { id } = useParams();
  const [league, setLeague] = useState();

  async function getLeague() {
    const { data, error } = await supabase.rpc("getGenericLeagueInfo", {
      league_id: `${id}%`,
    });
    if (error) {
      console.error(error);
    }
    if (data) {
      setLeague(data);
    }
  }

  useEffect(() => {
    getLeague();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div style={{ flex: 1, alignSelf: "center" }}>
      <Helmet>
        <title>Join My League</title>
      </Helmet>
      <div
        style={{
          margin: 10,
          textAlign: "center",
          alignItems: "center",
          alignSelf: "center",
          alignContent: "center",
          backgroundColor: "#00000099",
          maxWidth: 500,
          padding: 20,
        }}
      >
        <h1>Just 1 more step</h1>
        <div style={{ textAlign: "left" }}>
          <div>
            <b>League Name</b>: {league?.displayName}
          </div>
          <div>
            <b>Sport</b>: {league?.sportShortName}
          </div>
          <div>
            <b>Season</b>: {league?.seasonName}
          </div>
        </div>
        <h2>I've got the app!</h2>
        <div style={{ paddingBottom: 30 }}>
          <Link to={`statsdraft://join-league/${id}`}>
            <Button variant="contained">Join League Now</Button>
          </Link>
        </div>
        <h2>I need the app</h2>
        <AppDownloads />
      </div>
    </div>
  );
}

export default JoinLeague;

/* eslint-disable react-hooks/exhaustive-deps */
import "./SportSegmentationManager.css";

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  getSportYearSegments,
  upsertSportSegments,
} from "../../utils/SportYearSegmentsDatabase";

import { AppDataContext } from "../../providers/AppDataProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { getGamesBySportIdAndSeasonYear } from "../../utils/GamesDatabase";
import { useNavigate } from "react-router-dom";

function SportSegmentationManager() {
  const navigate = useNavigate();
  const { sports, refreshData } = useContext(AppDataContext);
  const [sport, setSport] = useState();
  const [selectedSportId, setSelectedSportId] = useState();
  const [year, setYear] = useState(dayjs().get("year"));
  const [prefix, setPrefix] = useState("Week");
  const [segments, setSegments] = useState([]);

  async function generateSegments() {
    if (sport && year) {
      //Get all games for a given sport and year
      const games = await getGamesBySportIdAndSeasonYear(sport.id, year);
      //look at week_number field to see if there is a good place break them up
      const foundSegments = [...new Set(games.map((g) => g.week_number))].sort(
        function (a, b) {
          return a - b;
        }
      );
      console.log("Found Segments", foundSegments);

      const firstGameMap = new Map();
      foundSegments.forEach((seg) => {
        const segGames = games
          .filter((g) => g.week_number === seg)
          .map((g) => g.gameTime)
          .sort((a, b) => dayjs(a).unix() - dayjs(b).unix());
        firstGameMap.set(seg, segGames[0]);
      });

      const createMe = [];

      foundSegments.forEach((seg, index) => {
        createMe.push({
          sport_id: sport.id,
          season_year: year,
          segment_number: index + 1,
          display_name: `${prefix} ${seg}`,
          game_mapping_value: `${seg}`,
          lock_time: dayjs(firstGameMap.get(seg))
            .add(15, "minutes")
            .toDate()
            .toISOString(),
        });
      });

      await upsertSportSegments(createMe);
      await lookupSegments();
    }
  }

  const changeSport = (event) => {
    const id = event.target.value;
    const thisSport = sports.find((s) => s.id === id);
    setSelectedSportId(id);
    setSport(thisSport);
  };

  async function lookupSegments() {
    if (sport && year) {
      const segs = await getSportYearSegments(sport.id, year);
      console.log(segs);
      setSegments(segs);
    }
  }

  useEffect(() => {
    lookupSegments();
  }, [sport, year]);

  useEffect(() => {
    if (!sport && sports && sports.length > 0) {
      setSport(sports[0]);
      setSelectedSportId(sports[0].id);
    }
  }, [sports]);

  useEffect(() => {
    const setup = async () => {
      await refreshData("sports");
    };
    setup();
  }, []);
  return (
    <>
      <div className="Admin-back">
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate("/admin");
          }}
        >
          Back to Admin
        </Button>
      </div>
      <div className="AdminNavigator-container">
        <div className="AdminNavigator-innerContainer">
          <Box
            sx={
              {
                // display: "flex",
              }
            }
          >
            <h2>Sport Year Segment Manager</h2>
            {selectedSportId && (
              <>
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Select
                    sx={{ marginRight: 4 }}
                    value={selectedSportId}
                    onChange={changeSport}
                  >
                    {sports &&
                      sports.map((sport, index) => (
                        <MenuItem key={index} value={sport.id}>
                          {sport.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                  <TextField
                    sx={{ marginRight: 4 }}
                    variant="standard"
                    label="Sport Year (YYYY)"
                    value={year}
                    type="number"
                    onChange={(event) => {
                      setYear(parseInt(event.target.value));
                    }}
                  />
                  <TextField
                    sx={{ marginRight: 4 }}
                    variant="standard"
                    label="Segment Number Prefix"
                    value={prefix}
                    onChange={(event) => {
                      setPrefix(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: 1 }}>
                  {(segments && segments.length > 0 && (
                    <>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Segment Order</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">
                                Game Mapping Value
                              </TableCell>
                              <TableCell>Roster Lock Time</TableCell>
                              <TableCell align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {segments.map((seg) => (
                              <>
                                <TableRow>
                                  <TableCell align="right">
                                    {seg.segment_number}
                                  </TableCell>
                                  <TableCell>{seg.display_name}</TableCell>
                                  <TableCell align="right">
                                    {seg.game_mapping_value}
                                  </TableCell>
                                  <TableCell>
                                    {dayjs(seg.lock_time).format("LLLL")}
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      aria-label="edit"
                                      color="primary"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      color="primary"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )) || (
                    <Box sx={{ flexDirection: "column" }}>
                      <h3>No Segments Found.</h3>
                      <Button variant="contained" onClick={generateSegments}>
                        Generate Season
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default SportSegmentationManager;

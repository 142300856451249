import "./HowToPlay.css";

import { Card, CardContent, CardHeader } from "@mui/material";

import React from "react";

function HowToPlay() {
  return (
    <Card className="howToPlay">
      <CardHeader className="howToPlayHeader" title="How to Play STATSdraft" />

      <CardContent className="howToPlayContent">
        <h3>HOW TO START</h3>
        <div className="howToPlaySection">
          <div>Join or Create a League</div>
          <div>Select the STATS you think winning teams will win</div>
        </div>
        <h3>HOW TO SCORE</h3>
        <div className="howToPlaySection">
          <div>2 points - When the winning team wins your STAT</div>
          <div>1 point - When the STAT or game is a tie</div>
          <div>0 points - When the losing team wins your STAT</div>
        </div>
        <h3>HOW TO WIN</h3>
        <div className="howToPlaySection">
          Our team at STATSdraft will score all the games from your league’s
          season and let you know how many points everyone scored. High score
          wins.
        </div>
      </CardContent>
    </Card>
  );
}

export default HowToPlay;

import { Helmet } from "react-helmet-async";
import HowToPlay from "../components/HowToPlay";
import React from "react";

function HowToPlayPage() {
  return (
    <>
      <Helmet>
        <title>STATSdraft - How to Play STATSdraft</title>
      </Helmet>
      <div className="block-1">
        <HowToPlay />
      </div>
    </>
  );
}
export default HowToPlayPage;

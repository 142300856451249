import "./Home.css";

import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AppDownloads } from "../components/AppDownloads";
import { AuthContext } from "../providers/AuthProvider";
import { Helmet } from "react-helmet-async";
import Login from "../components/Login";
import PublicLeagues from "../components/PublicLeagues";
import { Snackbar } from "@mui/material";
import { supabase } from "../supabase";
import { useContext } from "react";
import { useState } from "react";

// import { Button } from "@mui/material";

function Home() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { session } = useContext(AuthContext);
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    if (searchParams && searchParams.get("success")) {
      //token supplied, lets route to success
      navigate("/success");
    }
  }, [searchParams, navigate]);

  async function joinLeague(leagueId) {
    const { error } = await supabase.functions.invoke("accept-invite", {
      body: { leagueId: leagueId },
    });
    if (error) {
      console.error(error);
      alert(error.message);
    }

    setMessageBarOpen(true);
    setForceUpdate(new Date().getTime());
  }

  const handleClose = () => {
    setMessageBarOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>STATSdraft Home</title>
      </Helmet>
      <div className="block-1">
        <div className="welcome">Welcome to STATSdraft!</div>
        <div className="info-panel">
          A new way to play Fantasy Sports is here! Android and iOS apps are
          available below.
        </div>
        <AppDownloads />
        {(session && (
          <PublicLeagues
            forceUpdate={forceUpdate}
            joinLeagueFunction={joinLeague}
          />
        )) || <Login redirectUrl={"/"} />}

        <Snackbar
          open={messageBarOpen}
          autoHideDuration={8000}
          onClose={handleClose}
          message="League Joined. Open the STATSdraft mobile app to play!"
        />
      </div>
    </>
  );
}

export default Home;

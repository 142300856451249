import React, { useEffect, useState } from "react";

import JoinLeagueButton from "./JoinLeagueButton";
import { Skeleton } from "@mui/material";
import { supabase } from "../supabase";

function PublicLeagues({ joinLeagueFunction, forceUpdate }) {
  const [publicLeagues, setPublicLeagues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function updateLeagues() {
      setLoading(true);
      await getPublicLeagues();
      setLoading(false);
    }
    updateLeagues();
  }, [forceUpdate]);

  async function getPublicLeagues() {
    const leagueFilterFunction = (league) => {
      if (!league.isSeasonActive) return false; //don't allow if season isn't active
      if (league.is_complete) return false; //don't allow if league is already complete.
      if (league.is_draft_complete) return false; //don't show if it has already drafted
      if (league?.type !== "FFA") {
        if (league?.members?.length >= league?.max_players) return false; //don't show if full
      } else {
        const joinBy = new Date(league?.draftStart).getTime();
        if (joinBy < new Date().getTime()) {
          return false; //join by date has passed
        }
      }

      return true;
    };

    const { data, error } = await supabase
      .from("public_leagues_full_info")
      .select()
      .eq("isSeasonActive", true)
      .order("draftStart");
    if (error) {
      console.error(error);
    }
    if (data) {
      setPublicLeagues(data.filter((l) => leagueFilterFunction(l)));
    } else {
      setPublicLeagues([]);
    }
  }

  return (
    <>
      <h2>Public Leagues</h2>
      <div className="leaguesJoinList">
        {(!loading && (
          <>
            {publicLeagues.length === 0 && <div>No Public Leagues Found</div>}
            {publicLeagues.map((league) => (
              <JoinLeagueButton
                league={league}
                joinFunction={joinLeagueFunction}
              />
            ))}
          </>
        )) || (
          <>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={250}
              width={400}
              style={{
                backgroundColor: "#666666aa",
                borderRadius: 4,
                marginTop: 4,
                marginBottom: 8,
                marginRight: 4,
                marginLeft: 4,
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={250}
              width={400}
              style={{
                backgroundColor: "#666666aa",
                borderRadius: 4,
                marginTop: 4,
                marginBottom: 8,
                marginRight: 4,
                marginLeft: 4,
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={250}
              width={400}
              style={{
                backgroundColor: "#666666aa",
                borderRadius: 4,
                marginTop: 4,
                marginBottom: 8,
                marginRight: 4,
                marginLeft: 4,
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default PublicLeagues;

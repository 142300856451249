import { supabase } from "../supabase";

// const TABLE_NAME = "my_games";
const SRC_TABLE_NAME = "games";
export async function getGamesBySportIdAndSeasonYear(sportId, seasonYear) {
  const { data, error } = await supabase
    .from(SRC_TABLE_NAME)
    .select()
    .eq("sport_id", sportId)
    .eq("season_year", seasonYear);

  if (error) {
    console.log(error);
  }
  if (data) {
    return data;
  } else {
    return [];
  }
}

import "./WhatIsSTATSdraft.css";

import React from "react";

function WhatIsSTATSdraft() {
  return (
    <div className="whatIsSTATSdraft">
      <h2>What is STATSdraft</h2>
      <div className="whatIsSTATSdraftContent">
        STATSdraft is a new way to play Fantasy Sports. Instead of picking
        individual players you build a roster of team stats. You will choose
        things like: most 1st downs win, most shots on goals win, fewest
        penalties win, most 3pt shots win, etc...
      </div>
    </div>
  );
}
export default WhatIsSTATSdraft;

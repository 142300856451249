import "./Layout.css";

import { AppDataProvider } from "../providers/AppDataProvider";
import AuthProvider from "../providers/AuthProvider";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import React from "react";

const Layout = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="STATSdraft" />
        <meta
          property="og:description"
          content="STATSdraft is a new way to play fantasy sports. Download our app today and join the best way to play fantasy sports!"
        />
        <meta property="og:url" content="https://app.statsdraft.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="http://app.statsdraft.com/logo.png"
        />
        <meta property="og:image:alt" content="Play STATSdraft Today!" />
      </Helmet>
      <div
        style={{
          backgroundImage: `url("https://images.statsdraft.com/background.jpg")`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        className="App"
      >
        <AuthProvider>
          <AppDataProvider>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                flex: "0 1 auto",
              }}
            >
              <Header />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                flex: "1 1 auto",
                overflowY: "auto",
              }}
            >
              <Outlet />
            </div>
          </AppDataProvider>
        </AuthProvider>
      </div>
    </>
  );
};

export default Layout;

import { supabase } from "../supabase";

const TABLE_NAME = "locations";

export async function getAllLocations() {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select()
      .order("display_name");
    if (data) {
      return data;
    }
    if (error) {
      console.error("Error Looking up locations", error);
    }
  } catch (err) {
    console.error("Error Looking up locations", err);
  }
}
export async function getLocationBySlug(slug) {
  if (slug) {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .eq("slug", slug)
        .limit(1)
        .single();
      if (data) {
        return data;
      }
      if (error) {
        console.error("Error looking up location by slug", error);
      }
    } catch (err) {
      console.error("Error looking up location by slug", err);
    }
  }
  return null;
}

export async function getLocationById(id) {
  if (id) {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .eq("id", id)
        .limit(1)
        .single();
      if (data) {
        return data;
      }
      if (error) {
        console.error("Error looking up location by id", error);
      }
    } catch (err) {
      console.error("Error looking up location by id", err);
    }
  }
  return null;
}

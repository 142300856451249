import "./index.css";

import { ThemeProvider, createTheme } from "@mui/material";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#136D15",
    },
    secondary: {
      main: "rgb(149,189,7)",
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <App />
        </PostHogProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

import "./Signup.css";

import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthContext } from "../providers/AuthProvider";
import Login from "../components/Login";

function Signup() {
  const { session } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const redirectUrl = searchParams.get("redirectUrl");

  useEffect(() => {
    console.log("redirectUrl", redirectUrl);
    if (session && redirectUrl) {
      navigate(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <>
      {!session && (
        <div className="signup">
          <Login redirectUrl={redirectUrl} />
        </div>
      )}
    </>
  );
}

export default Signup;

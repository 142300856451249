import "./EventLoc.css";

import React, { useEffect, useState } from "react";

import { AppDownloads } from "../../components/AppDownloads";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet-async";
import HowToPlay from "../../components/HowToPlay";
import UpcomingEvents from "./UpcomingEvents";
import WhatIsSTATSdraft from "../../components/WhatIsSTATSdraft";
import { getLocationBySlug } from "../../utils/LocationsDatabase";
import { useParams } from "react-router-dom";

function EventLoc() {
  const { id } = useParams();
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doLookup = async () => {
      if (id) {
        setLoading(true);
        const loc = await getLocationBySlug(id);
        if (loc) {
          setLocation(loc);
        } else {
          setLocation(undefined);
        }
      } else {
        setLocation(undefined);
      }
      setLoading(false);
    };
    doLookup();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>STATSdraft - Events</title>
      </Helmet>
      <div className="block-1">
        {(loading && (
          <div>
            <CircularProgress />
          </div>
        )) || (
          <>
            <img
              src={location?.logo}
              alt="Location Logo"
              className="eventLogo"
            />
            {/* <h2>{location?.display_name}</h2> */}
            <h2>Upcoming Events</h2>
            <UpcomingEvents locationId={location?.id} slug={id} />
          </>
        )}
        <WhatIsSTATSdraft />
        <HowToPlay />
        <AppDownloads />
      </div>
    </>
  );
}

export default EventLoc;

import "./Login.css";

import React, { useEffect, useState } from "react";

import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../supabase";

const Login = ({ redirectUrl }) => {
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);

  function isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  }

  useEffect(() => {
    setShowBrowserWarning(isFacebookApp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator]);

  return (
    <div className="App-login">
      <h3 className="App-login-title">Sign In or Create Your Account</h3>
      {showBrowserWarning && (
        <div className="App-login-title">
          It looks like you are trying to login from a link that came in
          Facebook Messenger or Instagram. These browsers don't support Google
          and Apple Logins. Please open the link in Chrome or Safari.
        </div>
      )}
      <Auth
        supabaseClient={supabase}
        appearance={{
          theme: ThemeSupa,
          style: {
            button: {
              width: "auto",
            },
          },
          variables: {
            default: {
              colors: {
                brand: "#136d15",
                brandAccent: `gray`,
              },
            },
          },
        }}
        providers={showBrowserWarning ? [] : ["google", "apple", "facebook"]}
        redirectTo={`${process.env.REACT_APP_BASE_URL}${redirectUrl}`}
      />
    </div>
  );
};

export default Login;

import { supabase } from "../supabase";

const TABLE_NAME = "sport_year_segments";

export async function getSportYearSegments(sportId, sportYear) {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select()
    .eq("sport_id", sportId)
    .eq("season_year", sportYear);
  if (error) {
    console.error(error);
  }
  if (data) {
    return data;
  }
}
export async function upsertSportSegments(segments) {
  //   console.log(segments);
  const { error } = await supabase
    .from(TABLE_NAME)
    .upsert(segments, { onConflict: "id" });
  if (error) {
    console.error(error);
    throw new Error(error.message);
  }
}

import React, { createContext, useEffect, useState } from "react";

import { supabase } from "../supabase";

export const AppDataContext = createContext({});

export const AppDataProvider = ({ children }) => {
  const [sports, setSports] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [statDefs, setStatDefs] = useState([]);
  const [draftableStats, setDraftableStats] = useState([]);
  const [draftableStatFavs, setDraftableStatFavs] = useState([]);

  useEffect(() => {
    // refreshData();
  }, []);

  const logout = () => {
    supabase.auth.signOut().then((data) => {
      setTeams([]);
      setSeasons([]);
      setGames([]);
      setSports([]);
      setStatDefs([]);
      setDraftableStats([]);
      setDraftableStatFavs([]);
    });
  };

  const refreshData = async (type) => {
    console.log("Refreshing", type);
    if (!type || type === "games") {
      const { data, error } = await supabase
        .from("games")
        .select()
        .order("gameTime", { ascending: false });
      if (error) {
        console.error(error);
      }
      setGames(data);
    }

    if (!type || type === "teams") {
      const { data, error } = await supabase.from("teams").select();
      if (error) {
        console.error(error);
      }
      setTeams(data);
    }

    if (!type || type === "seasons") {
      const { data, error } = await supabase.from("seasons").select();
      if (error) {
        console.error(error);
      }
      setSeasons(data);
    }

    if (!type || type === "sports") {
      const { data, error } = await supabase
        .from("sports")
        .select()
        .order("emojii")
        .order("displayName");
      if (error) {
        console.error(error);
      }
      setSports(data);
    }

    if (!type || type === "stats_def") {
      const { data, error } = await supabase
        .from("stats_def")
        .select()
        .order("displayName");
      if (error) {
        console.error(error);
      }
      setStatDefs(data);
    }

    if (!type || type === "draftable_stats") {
      const { data, error } = await supabase
        .from("draftable_stats_full_info")
        .select()
        .order("statDefId");
      if (error) {
        console.error(error);
      }
      setDraftableStats(data);
    }
    if (!type || type === "draftable_stat_favs") {
      const { data, error } = await supabase
        .from("draftable_stat_favs")
        .select()
        .order("draftable_stat_id");
      if (error) {
        console.error(error);
      }
      setDraftableStatFavs(data);
    }
  };

  const lookupStatDefsBySportId = async (sportId) => {
    let theseDefs = statDefs;
    if (!theseDefs || statDefs.length === 0) {
      const { data, error } = await supabase
        .from("stats_def")
        .select()
        .order("displayName");
      if (error) {
        console.error(error);
      }
      theseDefs = data;
      console.log("theseDefs", theseDefs);
    }
    if (sportId && theseDefs) {
      return theseDefs.filter((sd) => sd.sportId === sportId);
    }
    return [];
  };

  const lookupDraftableStatsBySportId = (sportId) => {
    if (sportId && draftableStats) {
      return draftableStats.filter((ds) => ds.sportId === sportId);
    }
    return [];
  };

  const lookupSportById = (sportId) => {
    if (sportId && sports) {
      return sports.find((sport) => sport.id === sportId);
    }
    return undefined;
  };

  const upsertDraftableStats = async (ds) => {
    const { error } = await supabase.from("draftable_stats").upsert(ds);
    if (error) {
      console.error(error);
    }
    await refreshData("draftable_stats");
  };

  const deleteDraftableStat = async (draftableStatId) => {
    const { error } = await supabase
      .from("draftable_stats")
      .delete()
      .eq("id", draftableStatId);
    if (error) {
      console.error(error);
    }
    await refreshData("draftable_stats");
  };

  return (
    <AppDataContext.Provider
      value={{
        sports,
        seasons,
        teams,
        lookupSportById,
        lookupStatDefsBySportId,
        lookupDraftableStatsBySportId,
        upsertDraftableStats,
        deleteDraftableStat,
        games,
        statDefs,
        draftableStats,
        draftableStatFavs,
        refreshData,
        logout,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

import "./App.css";

import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminNavigator from "./pages/admin/AdminNavigator";
import { CircularProgress } from "@mui/material";
import Download from "./pages/Download";
import EventDeals from "./pages/events/EventDeals";
import EventLoc from "./pages/events/EventLoc";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import HowToPlayPage from "./pages/HowtoPlay";
import JoinLeague from "./pages/JoinLeague";
import Layout from "./pages/Layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import NotFound from "./pages/NotFound";
import Signup from "./pages/Signup";
import SportSegmentationManager from "./pages/admin/SportSegmentationManager";
import Success from "./pages/Success";
import UserHome from "./pages/users/UserHome";

function App() {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const helmetContext = {};

  //Admin lazy imports
  const AdminPage = React.lazy(() => import("./pages/admin/AdminPage"));
  const GameManager = React.lazy(() => import("./pages/admin/GameManager"));
  const GameEditor = React.lazy(() => import("./pages/admin/GameEditor"));
  const StatManager = React.lazy(() => import("./pages/admin/StatManager"));
  const StatEditor = React.lazy(() => import("./pages/admin/StatEditor"));
  const Reports = React.lazy(() => import("./pages/admin/Reports"));
  const EventManager = React.lazy(() => import("./pages/admin/EventManager"));
  const LocationManager = React.lazy(() =>
    import("./pages/admin/LocationManager")
  );

  //User lazy imports
  const Stats = React.lazy(() => import("./pages/Stats"));
  const Privacy = React.lazy(() => import("./pages/Privacy"));

  useEffect(() => {
    if (hash && hash.length > 1 && !hash.startsWith("#access_token")) {
      navigate(hash.replace("#", ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HelmetProvider context={helmetContext}>
        <React.Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="events/:id" element={<EventLoc />} />
              <Route
                path="events/:locationId/:eventId/deals"
                element={<EventDeals />}
              />
              <Route path="signup" element={<Signup />} />
              <Route path="how-to-play" element={<HowToPlayPage />} />
              <Route path="success" element={<Success />} />
              <Route path="download" element={<Download />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="join-league/:id" element={<JoinLeague />} />
              <Route path="join/:id" element={<JoinLeague />} />
              <Route path="stats" element={<Stats />} />
              <Route path="admin" element={<AdminPage />}>
                <Route index element={<AdminNavigator />} />
                <Route path="game-management" element={<GameManager />} />
                <Route path="game-management/:id" element={<GameEditor />} />
                <Route path="stat-management" element={<StatManager />} />
                <Route path="stat-management/:id" element={<StatEditor />} />
                <Route path="reports" element={<Reports />} />
                <Route path="event-management" element={<EventManager />} />
                <Route path="location/:id" element={<LocationManager />} />
                <Route
                  path="sport-segmentation-management"
                  element={<SportSegmentationManager />}
                />
              </Route>
              <Route path="users/:id" element={<UserHome />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </React.Suspense>
      </HelmetProvider>
    </LocalizationProvider>
  );
}

export default App;

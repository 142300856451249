import { supabase } from "../supabase";

const TABLE_NAME = "location_events";
export async function getUpcomingEventsByLocationId(locationId) {
  if (locationId) {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select()
      .eq("location_id", locationId);
    if (data) {
      return data;
    }
    if (error) {
      console.error("Error getting events at this location", error);
      return null;
    }
  }
  return null;
}

export async function getAllEventsByLocationId(locationId) {
  if (locationId) {
    console.log("Looking at ", locationId);
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select()
      .eq("location_id", locationId);
    if (data) {
      return data;
    }
    if (error) {
      console.error("Error getting events at this location", error);
      return null;
    }
  }
  return null;
}

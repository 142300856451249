import "./EventCard.css";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useContext } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import DateFormattter from "../../components/DateFormatter";
import { useNavigate } from "react-router-dom";

function EventCard({ evnt, slug }) {
  const { session } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Card key={evnt?.id} className="eventCard">
      <CardHeader className="eventCardHeader" title={evnt?.display_name} />
      <CardContent className="eventCardContent">
        <div className="eventCardMainText">
          Event starts at <DateFormattter dateString={evnt?.start_time} />
        </div>
        <div className="eventCardPromoText">{evnt?.promo_text}</div>
      </CardContent>
      <CardActions className="eventCardActions">
        {(!session && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              navigate(`/Signup?redirectUrl=/events/${slug}`);
            }}
          >
            Create an Account or Sign In to Join
          </Button>
        )) || (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              navigate(`/join/${evnt?.league_id}`);
            }}
          >
            Join League
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default EventCard;

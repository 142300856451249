import {
  AdminPanelSettings,
  Download,
  Info,
  Login,
  Logout,
  MoreVert,
  Timeline,
} from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";

import { AppDataContext } from "../providers/AppDataProvider";
import { AuthContext } from "../providers/AuthProvider";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const { session } = useContext(AuthContext);
  const { logout } = useContext(AppDataContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const localLogout = () => {
    logout();
    handleClose();
  };

  return (
    <header className="App-header">
      <div className="Header-row">
        <div className="logo">
          <img alt="Stats Draft Logo" src={logo} />
        </div>

        <Link
          className="header-link"
          to="/"
          style={{ fontWeight: "bold", paddingRight: "40px" }}
        >
          STATSdraft
        </Link>

        <>
          {(isMobile && (
            <div
              style={{
                display: "flex",
                flex: 1,

                justifyContent: "flex-end",
              }}
            >
              <IconButton aria-label="more" onClick={handleClick}>
                <MoreVert style={{ color: "white" }} />
              </IconButton>
              <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                <MenuItem>
                  <ListItemIcon>
                    <Info fontSize="small" />
                  </ListItemIcon>
                  <Link
                    to="how-to-play"
                    className="header-sublink menuText"
                    onClick={handleClose}
                  >
                    How to Play
                  </Link>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <Timeline fontSize="small" />
                  </ListItemIcon>
                  <Link
                    to="stats"
                    className="header-sublink menuText"
                    onClick={handleClose}
                  >
                    Stats
                  </Link>
                </MenuItem>

                {session?.user?.email.endsWith("statsdraft.com") && (
                  <MenuItem>
                    <ListItemIcon>
                      <AdminPanelSettings fontSize="small" />
                    </ListItemIcon>
                    <Link
                      className="header-sublink menuText"
                      to="admin"
                      onClick={handleClose}
                    >
                      Admin
                    </Link>
                  </MenuItem>
                )}
                <MenuItem>
                  <ListItemIcon>
                    <Download fontSize="small" />
                  </ListItemIcon>
                  <Link
                    className="header-sublink menuText"
                    to="download"
                    onClick={handleClose}
                  >
                    Download the App
                  </Link>
                </MenuItem>

                <MenuItem>
                  {(session && (
                    <>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>{" "}
                      <Link
                        className="header-sublink menuText"
                        to="/"
                        onClick={localLogout}
                      >
                        Log Out
                      </Link>
                    </>
                  )) || (
                    <>
                      {" "}
                      <ListItemIcon>
                        <Login fontSize="small" />
                      </ListItemIcon>{" "}
                      <Link
                        className="header-sublink menuText"
                        to="/Signup"
                        onClick={handleClose}
                      >
                        Log In
                      </Link>
                    </>
                  )}
                </MenuItem>
              </Menu>
            </div>
          )) || (
            <>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <Link className="header-sublink" to="how-to-play">
                  How to Play
                </Link>
                <Link className="header-sublink" to="stats">
                  Stats
                </Link>
                {session?.user?.email.endsWith("statsdraft.com") && (
                  <Link className="header-sublink" to="admin">
                    Admin
                  </Link>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link
                  className="header-sublink header-downloadButton"
                  to="/download"
                >
                  Download the App
                </Link>
                {(session && (
                  <Link className="header-sublink" to="/" onClick={logout}>
                    Log Out
                  </Link>
                )) || (
                  <Link
                    className="header-sublink header-loginButton"
                    style={{ justifySelf: "flex-end" }}
                    to="/Signup"
                  >
                    Log in
                  </Link>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </header>
  );
};
export default Header;

import React, { useEffect, useState } from "react";
function DateFormattter({ dateString }) {
  const [formattedDate, setFormattedDate] = useState("");
  const format = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    minute: "2-digit",
    hour12: true,
    hour: "2-digit",
  };

  useEffect(() => {
    if (dateString) {
      const thisDate = new Date(dateString);

      const fds = thisDate.toLocaleString("en-us", format).replace(",", " @");
      setFormattedDate(fds);
    } else {
      setFormattedDate("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString]);
  return <>{formattedDate}</>;
}

export default DateFormattter;

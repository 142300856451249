import "./UserHome.css";

import React, { useContext, useEffect, useState } from "react";

import { AppDownloads } from "../../components/AppDownloads";
import { AuthContext } from "../../providers/AuthProvider";
import { Helmet } from "react-helmet-async";
import JoinLeagueButton from "../../components/JoinLeagueButton";
import Login from "../../components/Login";
import PublicLeagues from "../../components/PublicLeagues";
import { Skeleton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { supabase } from "../../supabase";
import { useParams } from "react-router-dom";

function UserHome() {
  const { id } = useParams();
  const { session } = useContext(AuthContext);

  const [leagues, setLeagues] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  async function getUserLeagues(userId) {
    const { data, error } = await supabase.rpc(
      "get_active_public_leagues_by_user",
      { user_id: userId }
    );
    if (error) {
      console.error(error);
    }

    if (data) {
      setLeagues(data);
    } else {
      setLeagues([]);
    }
  }

  async function getSelectedUser(userId) {
    const { data, error } = await supabase
      .from("user_display_names")
      .select()
      .eq("profile_id", userId)
      .single();
    if (error) {
      console.error(error);
    }
    if (data) {
      setSelectedUser(data);
    } else {
      console.warn("No User Found", userId);
      setSelectedUser(undefined);
    }
  }

  async function joinLeague(leagueId) {
    const { error } = await supabase.functions.invoke("accept-invite", {
      body: { leagueId: leagueId },
    });
    if (error) {
      console.error(error);
      alert(error.message);
    }

    setMessageBarOpen(true);
    setForceUpdate(new Date().getTime());
  }

  const handleClose = () => {
    setMessageBarOpen(false);
  };

  useEffect(() => {
    async function updateLeagues() {
      setLoading(true);
      await getSelectedUser(id);
      await getUserLeagues(id);
      setLoading(false);
    }

    if (id) {
      updateLeagues();
    }
  }, [id, forceUpdate]);

  if (!session) {
    return (
      <div className="signup">
        <Login redirectUrl={`/users/${id}`} />
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>STATSdraft - User Leagues</title>
        </Helmet>
        <div className="block-1">
          <h2>{selectedUser?.display_name}'s Leagues</h2>
          <div className="leaguesJoinList">
            {(!loading && (
              <>
                {leagues.length === 0 && <div>No Leagues Found</div>}

                {leagues.map((league) => (
                  <JoinLeagueButton league={league} joinFunction={joinLeague} />
                ))}
              </>
            )) || (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={250}
                  width={400}
                  style={{
                    backgroundColor: "#666666aa",
                    borderRadius: 4,
                    marginTop: 4,
                    marginBottom: 8,
                    marginRight: 4,
                    marginLeft: 4,
                  }}
                />
              </>
            )}
          </div>
          <PublicLeagues
            joinLeagueFunction={joinLeague}
            forceUpdate={forceUpdate}
          />
          <AppDownloads />
          <Snackbar
            open={messageBarOpen}
            autoHideDuration={8000}
            onClose={handleClose}
            message="League Joined. Open the STATSdraft mobile app to play!"
          />
        </div>
      </>
    );
  }
}

export default UserHome;
